body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,::after, ::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --blue: #149ed9;
}

a{
  display: inline-block;
  text-decoration: none;
  color: black;
}

a:hover, button:hover{
  opacity: 0.8;
}

.maxWidth{
  max-width: 1100px;
  margin: auto;
}

.paddingHor{
  padding: 0 20px;
}

input[type="number"],input[type="text"], input[type="email"], select,input[type="date"] {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  font-size: 1rem;
  padding: 8px;
  display: inline-block;
  width: 100%;
  outline-color: #c8c8c8;
}

.primaryBtn{
  background: var(--blue);
  color: white;
  border: none;
  padding: 8px;
  width: 100%;
  border-radius: 100px;
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
  &:disabled{
    background:#e2e2e2;
    color: #a7a7a7;
  }
}

button{
  cursor: pointer;
}

button:disabled{
  cursor: not-allowed;
}

.blueHeading{
  color: #1b3c6e;
  text-align:center;
  line-height: 125%;
}

.blueHeading.italic{
  font-style: italic;
}

.centerImage{
  margin: auto;
  display: block;
}